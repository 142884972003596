:root {
  --header-height: 80px;
}
.Header {
  position: fixed;
  display: flex;
  width: 100%;
  align-items: center;
  height: var(--header-height);
  top: 0;
  z-index: 100;
  background-color: white;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 55px;
  padding-right: 40px;
  width: 100%;
}
.options-container {
  display: flex;
  gap: 30px;
  align-items: center;
}
.options-container > p {
  font-weight: 300;
  font-size: 15px;
  cursor: pointer;
  color: rgb(31, 31, 31);
  padding: 4px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  transition: border-bottom 0.15s, color 0.15s;
}
.options-container > p:hover {
  color: rgb(32, 136, 255);
  border-bottom: 1px solid rgb(32, 136, 255);
}
.language {
  display: flex;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
  transition: 0.15s;
}
.language:hover {
  background-color: rgb(241, 241, 241);
}
.language > p {
  font-weight: 300;
  font-size: 15px;
  color: rgb(31, 31, 31);
  transition: 0.15s;
}
.language-dropdown,
.menu-dropdown {
  background-color: white;
  position: absolute;
  top: 85px;
  right: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(241, 241, 241);
  border-radius: 10px;
  padding: 5px 5px;

  opacity: 0;
  transform: translateY(-20px);
  pointer-events: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.language-dropdown.open,
.menu-dropdown.open {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}
.language-dropdown > ul,
.menu-dropdown > ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.language-dropdown > ul > li,
.menu-dropdown > ul > li {
  cursor: pointer;
  font-weight: 300;
  font-size: 15px;
  color: rgb(31, 31, 31);
  padding: 10px 20px;
  border-radius: 8px;
}
.language-dropdown > ul > li:hover,
.menu-dropdown > ul > li:hover {
  background-color: rgb(241, 241, 241);
}
.logo-container {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.logo {
  width: 100px;
  height: 100px;
}
.dropdown-options {
  display: none;
}
.language-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 800px) {
  .header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .options-container {
    display: none;
  }
  .dropdown-options {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 10px;
    transition: 0.15s;
  }
  .dropdown-options:hover {
    background-color: rgb(241, 241, 241);
  }
  .language-dropdown {
    right: 20px;
  }
  .menu-icon {
    font-size: 30px !important;
  }
}
