.About {
  margin-top: 100px;
  padding: 45 0px;
  box-shadow: 0px 5px 5px rgba(181, 181, 181, 0.05);
}
.about-header {
  display: flex;
  justify-content: center;
}
.about-text {
  font-size: 30px;
  font-weight: 400;
}
.aboutus {
  padding: 0 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.aboutus > img {
  max-width: 100%;
  height: 450px;
  object-fit: cover;
}
.aboutus > p {
  max-width: 80%;
  text-align: center;
  font-weight: 300;
}
.partners {
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.partners > h1 {
  font-weight: 400;
  font-size: 40px;
  text-align: center;
}
.partners_logos {
  max-width: 100%;
  overflow: hidden;
  position: relative;
}
.partners_logos::before,
.partners_logos::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100px;
  height: 100%;
  z-index: 2;
}
.partners_logos::before {
  left: 0;
  background: linear-gradient(to right, white, transparent);
}
.partners_logos::after {
  right: 0;
  background: linear-gradient(to left, white, transparent);
}
.partners img {
  width: 100px;
  aspect-ratio: 1;
  border-radius: 100px;
  object-fit: cover;
}
.inner {
  display: flex;
  gap: 20px;
  padding: 10px 0;
  animation: scroll 40s linear infinite;
  width: fit-content;
}
.collage {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  padding: 10px 45px;
  max-width: 100%;
  padding-bottom: 40px !important;
  margin-bottom: 10px;
}
.collage > img {
  width: 500px;
  aspect-ratio: 1;
  object-fit: cover;
}
.collage-text {
  width: 460px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.collage-text p {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px;
}
.collage-text > ul {
  list-style-type: none;
  padding: 0;
  width: fit-content;
}
.collage-text li {
  color: rgb(31, 31, 31);
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(32, 136, 255, 0);
  cursor: pointer;
  transition: 0.15s;
  width: max-content;
}
.collage-text li:hover {
  color: rgb(32, 136, 255);
  border-bottom: 1px solid rgb(32, 136, 255);
  transform: translateX(3px);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50% - 10px));
  }
}

@media (max-width: 800px) {
  .collage {
    flex-direction: column;
    gap: 30px;
  }
  .collage > img {
    width: 100%;
    max-width: 500px;
  }
  .collage-text {
    width: 100%;
    max-width: 500px;
  }
  .aboutus > img {
    height: 200px;
  }
  .partners > h1 {
    font-size: 24px;
  }
}
