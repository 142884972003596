.Questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f5f6f8;
  padding: 50px 0;
  gap: 10px;
}
.Questions > h1 {
  font-size: 20px;
  font-weight: 300;
  color: rgb(31, 31, 31);
  margin-bottom: 20px;
}
.email-container {
  width: 200px;
  height: 30px;
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  font-family: "Montserrat", serif;
}
.message-container {
  width: 400px;
  border: none;
  border-radius: 10px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 80px;
  font-family: "Montserrat", serif;
}
.email-container::placeholder,
.message-container::placeholder {
  font-size: 12px;
  font-family: "Montserrat", serif;
  color: rgb(200, 200, 200);
}
.message-container:focus,
.email-container:focus {
  outline: none;
}
.message {
  position: relative;
}
.send-icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: rgb(200, 200, 200);
  cursor: pointer;
  transition: 0.15s;
}
.send-icon:hover {
  color: rgb(31, 31, 31);
}

@media (max-width: 600px) {
  .message-container {
    width: 300px;
  }
}
