.Contact {
  margin-top: 100px;
}
.map-container {
  display: flex;
  justify-content: center;
  gap: 50px;
  padding: 0 45px;
  margin-bottom: 50px;
}
.map-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  max-height: 100%;
}
.map {
  flex: 2;
  height: 100%;
  z-index: 1;
}
.map-info > div {
  flex: 1;
  background-color: #f5f6f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  gap: 10px;
}
.map-info > div > h1 {
  font-size: 20px;
  font-weight: 400;
  color: rgb(31, 31, 31);
}
.map-info > div > p {
  font-size: 14px;
  font-weight: 300;
  color: rgb(31, 31, 31);
  cursor: pointer;
  transition: 0.15s;
  display: inline-block;
}
.map-info > div > p:hover {
  color: rgb(32, 136, 255);
}
.contact-icon {
  margin-right: 5px;
  font-size: 14px;
}
.media-icons {
  display: flex;
  gap: 10px;
  justify-content: start;
}
.media-icon {
  color: rgb(31, 31, 31);
  transition: color 0.15s;
  cursor: pointer;
}
.media-icon:hover {
  color: rgb(32, 136, 255);
}

@media (max-width: 800px) {
  .map-container {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .map-info,
  .map {
    width: 100%;
    padding: 0;
  }
  .map-info > div {
    padding: 20px;
  }
}
