.Ceiling {
  margin-top: 100px;
  margin-bottom: 10px;
  padding-bottom: 35px;
  box-shadow: 0px 5px 5px rgba(181, 181, 181, 0.05);
}
.ceiling-header > h1 {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
}
.ceiling-content {
  padding: 0 100px;
  margin-top: 50px;
  display: flex;
  align-items: center;
}
.ceiling-content-image,
.ceiling-content-details {
  flex: 1;
  display: flex;
}
.ceiling-content-details {
  flex-direction: column;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-size: 18px;
  line-height: 28px;
  color: rgb(31, 31, 31);
}
.ceiling-content-image {
  justify-content: start;
}
.ceiling-content-image > img {
  width: 500px;
  aspect-ratio: 1;
  object-fit: cover;
}
.back-button {
  background-color: white;
  color: rgb(32, 136, 255);
  font-family: "Montserrat", sans-serif;
  border: 1px solid rgb(32, 136, 255);
  padding: 15px 0;
  width: 130px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.15s, color 0.15s;
}
.back-button:hover {
  background-color: rgb(32, 136, 255);
  color: white;
}

@media (max-width: 800px) {
  .ceiling-content {
    flex-direction: column;
    padding: 0 20px;
  }
  .ceiling-content-image > img {
    width: 100%;
    aspect-ratio: unset;
  }
  .ceiling-header > h1 {
    font-size: 30px;
  }
  .ceiling-content-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
  }
}
