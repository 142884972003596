.notification {
  position: fixed;
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  animation: fadeInOut 2s ease;
  z-index: 1000;
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
