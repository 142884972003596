.HomeCeilings {
  display: flex;
  flex-direction: row;
  padding: 0 100px;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.HomeCeilings.visible {
  opacity: 1;
}
.home-ceilings .HomeCeilings:nth-child(even) {
  flex-direction: row-reverse;
}
.home-ceilings .HomeCeilings:nth-child(even) .ceiling-image {
  justify-content: flex-start;
}
.ceiling-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ceiling-image {
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
}
.ceiling-image > img {
  width: 500px;
  aspect-ratio: 1;
  object-fit: cover;
}
.ceiling-info > h1 {
  font-size: 40px;
  font-weight: 400;
}
.ceiling-info > p {
  font-size: 18px;
  line-height: 28px;
  color: rgb(31, 31, 31);
}
.ceiling-info > button {
  background-color: white;
  color: rgb(32, 136, 255);
  font-family: "Montserrat", sans-serif;
  border: 1px solid rgb(32, 136, 255);
  padding: 15px 0;
  width: 140px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.15s, color 0.15s;
}
.ceiling-info > button:hover {
  background-color: rgb(32, 136, 255);
  color: white;
}

@media (max-width: 1250px) {
  .HomeCeilings,
  .home-ceilings .HomeCeilings:nth-child(even) {
    flex-direction: column;
    padding: 0 20px;
    gap: 25px;
  }
  .ceiling-image > img {
    width: 100%;
    aspect-ratio: unset;
  }
  .ceiling-info {
    align-items: center;
    text-align: center;
  }
  .ceiling-info > h1 {
    font-size: 30px;
  }
  .ceiling-info > p {
    display: none;
  }
}
